import React from 'react';
import { sidebaritem } from '../../types/sidebar';
import { TbHome, TbList } from "react-icons/tb";
import { BsClockHistory } from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
export const SidebarData: sidebaritem[] = [
    {
        title: 'HOME',
        path: '/',
        icon: <TbHome className='p-1'/>
        
    },
    {
        title: 'EVENTS',
        path: '/events',
        icon: <TbList className='p-1'/>
        
    },
    {
        title: 'SCHEDULES',
        path: '/schedules',
        icon: <BsClockHistory className='p-1'/>
    },
    {
        title: 'TARIFFS',
        path: '/tariffs',
        icon: <LiaFileInvoiceDollarSolid className='p-1'/>
    }
];