import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorStyles } from './errorStyles';

const LoginError = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/login');
    };

    return (
        <ErrorStyles.Container>
            <ErrorStyles.Title>Login Error</ErrorStyles.Title>
            <ErrorStyles.Message>We're sorry, but there was an error with your login attempt. Please try again.</ErrorStyles.Message>
            <ErrorStyles.HomeButton onClick={handleButtonClick}>Go to Login</ErrorStyles.HomeButton>
        </ErrorStyles.Container>
    );
};

export default LoginError;