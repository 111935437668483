import { Toast } from "primereact/toast";
import React,{ useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { ScrollPanel } from "primereact/scrollpanel";
import { LStyles } from '../events/EventsLog/eventlogsStyles';
import { EnrollStyles } from './enrolluserStyles';
import { showToast } from '../../Utils/Utility';

interface FormFieldProps {
  label: string;
  children: React.ReactNode;
}

const FormField = ({ label, children }: FormFieldProps) => (
  <div className="col-4">
    <EnrollStyles.LeftColumnContainer className="col-12"><span>{label}<span className="text-red-600"> *</span></span></EnrollStyles.LeftColumnContainer>
    <EnrollStyles.RightColumnContainer className="col-12">
      {children}
    </EnrollStyles.RightColumnContainer>
  </div>
);

interface RadioGroupProps {
  label: string;
  options: { name: string; key: number }[];
  onSelect: (e: any) => void;
  name: string;
}


const RadioGroup = ({ label, options, onSelect, name, selectedValue, selectedValue2 }: RadioGroupProps & { selectedValue: any, selectedValue2?: any }) => (
  <FormField label={label}>
    {options.map(option => (
      <div key={option.key} className="col-12 sm:col-6 md:col-5 lg:col-3 xl:col-2">
        <div className="flex align-items-center">
          <RadioButton
            inputId={option.key.toString()}
            name={name}
            value={option}
            onChange={onSelect}
            checked={option.key === selectedValue?.key || option.key === selectedValue2?.key}
            data-testid="signal-payload"
          />
          <label htmlFor={option.key.toString()} className="ml-2">{option.name}</label>
        </div>
      </div>
    ))}
  </FormField>
);

export const EnrollUser = () => {
  
  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedValue2, setSelectedValue2] = useState<any>();
  const toast = useRef<Toast>(null);

  const formValues = [
    { name: 'Yes', key: 1 },
    { name: 'No', key: 2 }
  ];

  const formValues2 = [
    { name: 'Yes', key: 3 },
    { name: 'Not started', key: 4 },
    { name: 'In process', key: 5 }
  ];

  const [scrollHeight, setScrollHeight] = useState(window.innerHeight + 'px');

  const updateHeight = () => {
    setScrollHeight(
      window.innerHeight < 600
        ? window.innerHeight - 80 + 'px'
        : window.innerHeight - 90 + 'px'
    );
  };

  useEffect(() => {
    updateHeight();
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSubmit = () => {
    showToast(toast, 'success', 'Form submitted successfully', '', false);
  }
  return (
    <>
     <LStyles.EventsLogContainer>
      <Toast ref={toast} />
      <ScrollPanel style={{ width: '100%', height: scrollHeight}}>
        <EnrollStyles.EventsInputContainer className="grid grid-nogutter col-12">
          <h2>Ford Energy Rewards - Vehicle-2-Home (V2H) Pilot - National Grid</h2>
          <FormField label="1.First Name">
            <InputText data-testid="first-name" placeholder="Enter your first name" />
          </FormField>
          <FormField label="2.Last Name">
            <InputText data-testid="last-name" placeholder="Enter your last name" />
          </FormField>
          <FormField label="3.Home Address">
            <InputText data-testid="address" placeholder="Enter your home address" />
          </FormField>
          <FormField label="4.City">
            <InputText data-testid="city"  placeholder="Enter your city" />
          </FormField>
          <FormField label="5.State">
            <InputText data-testid="state" placeholder="Enter your state" />
          </FormField>
          <FormField label="6.Zip">
            <InputText data-testid="zip-code" placeholder="Value must be zip" />
          </FormField>
          <FormField label="7.Email">
            <InputText data-testid="email" placeholder="Enter your email"/>
          </FormField>
          <FormField label="8.Phone number">
            <InputText data-testid="phone" placeholder="Enter your phone number"/>
          </FormField>
          <FormField label="9.National Grid Account Number">
            <InputText data-testid="account-number" placeholder="Enter your grid account number"/>
          </FormField>
          <RadioGroup
  label="10.Interconnection completed?"
  options={formValues2}
  onSelect={(e: any) => setSelectedValue2(e.value)}
  name="interconnection"
  selectedValue={selectedValue2}
  data-testid="connection"
/>
<RadioGroup
  label="11.Do you have solar panels installed at your home?"
  options={formValues}
  onSelect={(e: any) => setSelectedValue(e.value)}
  name="solar"
  selectedValue={selectedValue}
  data-testid="solar"
/>
          <EnrollStyles.RowContainer>
          <EnrollStyles.EventsContainerButton id="clear">Clear</EnrollStyles.EventsContainerButton>
            <EnrollStyles.EventsContainerButton id="publish" onClick={handleSubmit}>Submit</EnrollStyles.EventsContainerButton>
          </EnrollStyles.RowContainer>
        </EnrollStyles.EventsInputContainer>
      </ScrollPanel>
      </LStyles.EventsLogContainer>
    </>
  );
};