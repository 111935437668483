import styled from "styled-components";

const AppComponent = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

const MainComponent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow : 9;
  padding: 0px;
   @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const ContentPage = styled.div`
  display: flex;
  flex-grow : 9;
  border-top: 4px solid var(--primary-ui);
`;

export const AppStyles = {
    AppComponent,
    MainComponent,
    ContentPage,
}
