import styled from "styled-components";

const EventsInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`;

const LeftColumnContainer = styled.div`
    margin-right: 1rem;
`;

const RightColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const EventsContainerButton = styled.button`
  width: 12rem;
  height: 45px;
  font-size: 14px;
  color: white;
  margin: 5px;
  background-color: var(--primary-ui);
  border-radius: 5px;
  border: 2px solid var(--primary-ui);
  cursor: pointer;
`;

export const EnrollStyles = {
    EventsInputContainer,
    RowContainer,
    LeftColumnContainer,
    RightColumnContainer,
    EventsContainerButton
}