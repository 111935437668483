import styled from "styled-components";

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    background-color: var(--primary-ui);
    transition: 250ms;

    @media (max-width: 768px) {
        flex-direction: row;
        // align-items: center;
        justify-content: space-evenly;
    }
`;

const SidebarLink = styled.div`
    height: 2.5rem;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    padding: 3rem 0rem;
    margin: 2px;
    text-decoration: none;
    color: #ffffff;
    &:hover {
        color: var(--primary-ui);
        background-color: #ffffff;
        border-radius: 10px;    
    }

    @media (max-width: 768px) {
        // height:0rem;
        padding: 1rem 0.2rem;
    }
`;

const SidebarLabel = styled.div`
    display : flex;
    align-items: center;
`;

const SidebarIcon = styled.div<{$hover:boolean;}>`
    display : flex;
    align-items: center;
    padding : 8px;
    fill :${props => props.$hover ? "var(--primary-ui)" : "#000000"};
    @media (max-width: 768px) {
        padding : 8px 0px 8px 0px;
    }
`;

const SidebarWrap = styled.div`
    
    @media (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row; 
    }
`;

const SidebarLinkWrapper = styled.div`
    display : flex;
    align-items: center;
    // justify-content: space-around;
    flex-direction:column; 
     
    @media (max-width: 768px) {
        justify-content: space-around;
        flex-direction:row; 
    }
    
`;

export const SStyles = {
    SidebarContainer,
SidebarLink,
SidebarLabel,
SidebarIcon,
SidebarWrap,
SidebarLinkWrapper
}