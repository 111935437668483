import styled from "styled-components";

const Container = styled.div`
display: inline-flex;
width: 100%;
flex-direction: column;
align-items: center;
justify-content: center;
height:75%;
`;

const Title = styled.h1`
font-size: 2rem;
margin-bottom: 1rem;
`;

const Message = styled.p`
margin-bottom: 1.5rem;
`;

const HomeButton = styled.button`

background-color: var(--primary-ui);
  color: #fff;
  font-size: 1rem;
  font-weight: 900;
  border-radius: 5px;
  border: 0px;
  height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ErrorStyles = {
    Container,
    Title,
    Message,
    HomeButton
}