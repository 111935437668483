import React, { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { showToast } from '../../Utils/Utility';
import { confirmPopup } from 'primereact/confirmpopup';
import { TbCopy, TbInfoCircle, TbTrash } from 'react-icons/tb';
import { FiDownload } from 'react-icons/fi';
import { EOverlayStyles } from '../events/EventsOverlay/EventsOverlayStyles';
import { Toast } from 'primereact/toast';
import { UploadDeleteService } from '../../service/uploadService';

export const ActionButtons = ({ rowData, handleDownload, handleChildEvent, setIsLoading, pageTitle }: any) => {
    const op = useRef<any>(null);
    const toast = useRef<Toast>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(rowData.generation)
            .then(() => {
                showToast(toast, "success", "Id copied to clipboard", "", false);
            })
            .catch((err) => {
                showToast(toast, "error", `Failed to copy tooltip content : ${err}`, "", false);
            });
    };

    const deleteService = async (rowData: any) => {
        setIsLoading(true);
        await UploadDeleteService(
            rowData.metadata.folder,
            rowData.metadata.filenameInBucket,
            rowData.generation
        )
            .then(() => {
                setIsLoading(false);
                handleChildEvent("Cancelled", rowData.metadata.filename);
            })
            .catch((error) => {
                setIsLoading(false);
                showToast(
                    toast,
                    "error",
                    "Tariff Cancellation Failed " + error,
                    "",
                    false
                );
            });
    };

    const confirmDelete = (event: any, rowData: any) => {
        if (isDialogOpen) return;
        setIsDialogOpen(true);
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to delete this file?',
            icon: <TbTrash />,
            defaultFocus: 'reject',
            accept: () => {
                deleteService(rowData);
                setIsDialogOpen(false);
            },
            reject: () => {
                setIsDialogOpen(false);
            },
            onHide: () => {
                setIsDialogOpen(false);
            },
            acceptClassName: 'p-button-danger',
            acceptLabel: "Yes",
        });
    };

    return (
        <div className="grid m-1">
            <Button
                text
                icon={<FiDownload className="ford-primary" />}
                style={{ height: '0.25rem' }}
                onClick={() => handleDownload(rowData)}
                aria-label="download"
            />
            {pageTitle !== "MnV Data" ? 
            <Button
            text
            icon={<TbTrash />}
            style={{ height: '0.25rem' }}
            onClick={(e) => confirmDelete(e, rowData)}
            className="p-button-danger"
            aria-label="trash"
            />
            :''}
            <Button
                text
                icon={<TbInfoCircle />}
                style={{ height: '0.25rem' }}
                onClick={(e: any) => op.current?.toggle(e)}
                aria-label="info"
                data-testid="info-button"
            />
            <OverlayPanel
                ref={op}
                className="text-sm fadein animation-duration-100"
                closeOnEscape
                dismissable
                pt={{ content: { className: 'p-2' } }}
            >
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <EOverlayStyles.KeyCellStyle>
                                Generation ID
                            </EOverlayStyles.KeyCellStyle>
                            <EOverlayStyles.CellStyle>
                                <span>{rowData?.generation}</span>
                                <button
                                    className="ml-2"
                                    onClick={handleCopy}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    aria-label="copy"
                                    data-testid="copy-button" 
                                >
                                    <TbCopy />
                                </button>
                                
                            </EOverlayStyles.CellStyle>
                        </tr>
                    </tbody>
                </table>
            </OverlayPanel>
        </div>
    );
};