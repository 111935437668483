import { useState } from 'react';
import { DataTablePageEvent } from 'primereact/datatable';

export const usePagination = () => {
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const onPageChange = (event: DataTablePageEvent) => {
    setLoading(true);
    setTimeout(() => {
      setFirst(event.first);
      setRows(event.rows);
      setLoading(false);
    }, 500);
  };

  return { loading, first, rows, onPageChange };
};
