import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primeflex/primeflex.css'
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Auth from "./components/auth/auth";
import { EventsLog } from './components/events/EventsLog/events_log';
import Dashboard from './components/info/info';
import {SchedulesLog} from './components/schedules/SchedulesLog/schedulesLog';
import StartPage from './components/startpage/start_page';
import { TariffsLog } from './components/tariffs/tariffsLog';
import ErrorPage from './components/error/error';
import { EnrollUser } from './components/enrolluser/enrolluser';
import { MnVLog } from './components/mnv/mnvLog';
import { UploadMnV } from './components/mnv/uploadMnV';
import LoginError from './components/error/loginError';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}>
                <Route path="/login" element={<Auth/>}></Route>
                <Route path= "/events" element={<EventsLog/>}></Route>
                <Route path= "/schedules" element={<SchedulesLog/>}></Route>
                <Route path= "/info" element={<Dashboard/>}></Route>
                <Route path= "/tariffs" element={<TariffsLog/>}></Route>
                <Route path= "/enrolluser" element={<EnrollUser/>}></Route>
                <Route path= "/viewmnv" element={<MnVLog/>}></Route>
                <Route path= "/uploadmnv" element={<UploadMnV/>}></Route>
                <Route path= "/" element={<StartPage/>}></Route>
                <Route path= "/error" element={<ErrorPage/>}></Route>
                <Route path= "/loginerror" element={<LoginError/>}></Route>
            </Route>
        </Routes>
    </BrowserRouter>
);
