import styled from "styled-components";

const HeaderText = styled.div.attrs({ className: "scalein animation-duration-200" })`
    font-size: 1.8em;
    font-weight: bold;
    background: var(--primary-ui);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const StartStyles = {
    HeaderText
}