import React, { FunctionComponent, useState, useEffect, useRef, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Payload, signalPayloadValues } from '../../../types/signalValues';
import { EventScheduleService, UpdateScheduleService } from '../../../service/eventScheduleService';
import { showToast } from '../../../Utils/Utility';
import { EStyles } from '../../events/EventsForm/eventsformStyles';
import { signalNames, vens } from '../../events/EventsForm/eventsFormData';

type ScheduleFormProps = {
  onPublishEvent: (type: string, data: string) => void;
  setVisible: (data: boolean) => void;
  setLoader: (data: boolean) => void;
  mode: 'create' | 'edit';
  rowData?: any;
};

export const ScheduleForm: FunctionComponent<ScheduleFormProps> = ({
  onPublishEvent,
  setVisible,
  setLoader,
  mode,
  rowData,
}) => {
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [formvalue, setFormvalue] = useState<{ [key: string]: any }>({
    scheduleTime: null,
    target: null,
    vtnName: 'FORD_VTN_SIMULATOR',
    beginDate: null,
    endDate: null,
    signalName: 'SIMPLE',
    area: '48216',
    signalPayload: '',
    testEvent: false,
    duration: 'PT60',
  });
  const beginDatePickerRef = useRef<DatePicker>(null);
  const endDatePickerRef = useRef<DatePicker>(null);
  const scheduleDatePickerRef = useRef<DatePicker>(null);
  const [selectedScheduleDate, setSelectedScheduleDate] = useState<Date | null>(null);
  const [selectedScheduleDateTemp, setSelectedScheduleDateTemp] = useState<Date | null>(null);
  const [selectedBeginDate, setSelectedBeginDate] = useState<Date | null>(null);
  const [selectedBeginDateTemp, setSelectedBeginDateTemp] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedEndDateTemp, setSelectedEndDateTemp] = useState<Date | null>(null);
  const [selectedPayload, setSelectedPayload] = useState<Payload | null>(null);
  useEffect(() => {
    if (mode === 'edit' && rowData?.payload) {
      const startTime = new Date(rowData.payload.start_time);
      setSelectedBeginDate(startTime);
      setSelectedBeginDateTemp(startTime);

      const endTime = new Date(rowData.payload.end_time);
      setSelectedEndDate(endTime);
      setSelectedEndDateTemp(endTime);

      const schTime = new Date(rowData.schedule);
      setSelectedScheduleDate(schTime);
      setSelectedScheduleDateTemp(schTime);

      const initialPayload = signalPayloadValues.find(
        (payload) => payload.key === rowData.payload.signal_payload
      );
      if (initialPayload) {
        setSelectedPayload(initialPayload);
        setFormvalue((prev) => ({ ...prev, signalPayload: initialPayload.key }));
      }

      setFormvalue((prev) => ({
        ...prev,
        beginDate: rowData.payload.start_time,
        endDate: rowData.payload.end_time,
        scheduleTime: rowData.schedule,
        target: rowData.payload.ven_id,
        testEvent: rowData.payload.test_event,
      }));
    }
  }, [mode, rowData]);

  const handleDateChange = (dateType: 'scheduleTime' | 'beginDate' | 'endDate') => {
    let selectedDateTemp, setSelectedDate;
    switch (dateType) {
      case 'scheduleTime':
        selectedDateTemp = selectedScheduleDateTemp;
        setSelectedDate = setSelectedScheduleDate ;
        break;
      case 'beginDate':
        selectedDateTemp = selectedBeginDateTemp;
        setSelectedDate = setSelectedBeginDate;
        break;
      case 'endDate':
        selectedDateTemp = selectedEndDateTemp;
        setSelectedDate = setSelectedEndDate;
        break;
    }
    setSelectedDate(selectedDateTemp);
    let payload = { ...formvalue };
    payload[dateType] = selectedDateTemp;
    setFormvalue(payload);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let payload = { ...formvalue };
    payload[e.target.id] = e.target.value;
    setFormvalue(payload);
  };

  const handleCheckBoxChange = (id: string, checked: boolean) => {
    let payload = { ...formvalue };
    payload[id] = checked;
    setFormvalue(payload);
  };

  const handleRadioBoxChange = (value: any) => {
    let payload = { ...formvalue };
    payload['signalPayload'] = value.key;
    setFormvalue(payload);
    setSelectedPayload(value);
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      if (validateEventPayload(formvalue)) {
        setLoader(true);
        let response;
        if (mode === 'create') {
          response = await EventScheduleService(formvalue);
        } else if (mode === 'edit') {
          response = await UpdateScheduleService(formvalue, rowData?.id);
        }
        if (response?.status === 200 || response?.status === 201) {
          setVisible(false);
          onPublishEvent(mode === 'create' ? 'Scheduled' : 'Updated', response?.data?.data?.request_id);
        } else if (response?.status === 403) {
          navigate('/login');
        } else {
          setVisible(false);
          setLoader(false);
          showToast(toast, 'error', 'Failure', 'Server returned ' + response?.status, false);
        }
      }
    } catch (error) {
      setLoader(false);
      showToast(
        toast,
        'error',
        'Failure',
        `${mode === 'create' ? 'Failed' : 'Unable to update'}: ${error}`,
        false
      );
    }
  };

  function validateEventPayload(formvalue: { [key: string]: any }) {
    const isNull = Object.values(formvalue).some((value) => {
      return value === null || value === '';
    });
    if (isNull) {
      showToast(
        toast,
        'error',
        'Payload Validation Error',
        'Please enter all the required input fields',
        false
      );
      return false;
    } else {
      if (new Date(formvalue['scheduleTime']) <= new Date()) {
        showToast(
          toast,
          'error',
          'Invalid Schedule Time',
          'Schedule time should be greater than the current time',
          false
        );
        return false;
      }
      if (new Date(formvalue['scheduleTime']) > new Date(formvalue['beginDate'])) {
        showToast(
          toast,
          'error',
          'Please Verify Schedule Date & Time',
          'Schedule time should be before event start date',
          false
        );
        return false;
      }
      if (
        new Date(formvalue['beginDate']) > new Date() &&
        new Date(formvalue['endDate']) > new Date(formvalue['beginDate'])
      ) {
        return true;
      } else {
        showToast(
          toast,
          'error',
          'Payload Validation Error',
          'Please check the calendar inputs',
          false
        );
        return false;
      }
    }
  }

  function isFutureDate(date: Date) {
    const today = new Date();
    const tempDate = new Date(date.getTime());
    today.setHours(0, 0, 0, 0);
    tempDate.setHours(0, 0, 0, 0);
    return tempDate > today;
  }

  const clearForm = () => {
    setFormvalue({
      scheduleTime: null,
      target: null,
      vtnName: 'FORD_VTN_SIMULATOR',
      beginDate: null,
      endDate: null,
      signalName: 'SIMPLE',
      area: '48216',
      signalPayload: '',
      testEvent: false,
      duration: 'PT60',
    });
    setSelectedBeginDate(null);
    setSelectedEndDate(null);
    setSelectedBeginDateTemp(null);
    setSelectedEndDateTemp(null);
    setSelectedScheduleDate(null);
    setSelectedPayload(null);
  };

  const handleDateTextBoxClick = (pickerRef: React.RefObject<DatePicker>) => {
    if (pickerRef.current) {
      pickerRef.current.setOpen(true);
    }
  };  

  return (
    <EStyles.EventsFormContainer className="p-grid p-fluid">
      <Toast ref={toast} />
      <EStyles.EventsInputContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer className="mt-4">
            <span>Schedule Date & Time :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <EStyles.CheckboxContainer>
              <Checkbox
                data-testid="virtual-end-node"
                inputId="ven"
                name="ven"
                disabled
                onChange={(e) => handleCheckBoxChange('ven', e.checked!)}
                checked={true}
              />
              <label htmlFor="ven">Virtual End Node</label>
            </EStyles.CheckboxContainer>
            <InputText
              data-testid="schedule-date"
              id="scheduleTime"
              value={
                selectedScheduleDate
                  ? selectedScheduleDate
                      .toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                      .replace(',', '')
                  : ''
              }
              onClick={() => handleDateTextBoxClick(scheduleDatePickerRef)}
            />
            <EStyles.StyledDatePicker
              ref={scheduleDatePickerRef}
              selected={selectedScheduleDateTemp}
              onChange={(date: Date) => {
                setSelectedScheduleDateTemp(date);
              }}
              showTimeSelect
              onCalendarClose={() => handleDateChange('scheduleTime')}
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="time"
              minDate={new Date()}
              minTime={
                new Date(
                  selectedScheduleDateTemp && isFutureDate(selectedScheduleDateTemp)
                    ? new Date().setHours(0, 0)
                    : new Date()
                )
              }
              maxTime={new Date(new Date().setHours(23, 59))}
              dateFormat="MM/dd/yyyy HH:mm"
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer className="mt-2">
            <span>Target :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <Dropdown
              data-testid="target"
              id="target"
              value={formvalue['target']}
              onChange={(e:any) => handleFormChange(e)}
              options={vens}
              optionLabel="name"
              optionValue="value"
              appendTo={'self'}
              placeholder="Select Target"
              className="w-full"
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer>
            <span>Event Start :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <InputText
              data-testid="begin-date"
              id="beginDate"
              value={
                selectedBeginDate
                  ? selectedBeginDate
                      .toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                      .replace(',', '')
                  : ''
              }
              onClick={() => handleDateTextBoxClick(beginDatePickerRef)}
            />
            <EStyles.StyledDatePicker
              ref={beginDatePickerRef}
              selected={selectedBeginDateTemp}
              onChange={(date: Date) => {
                setSelectedBeginDateTemp(date);
              }}
              showTimeSelect
              onCalendarClose={() => handleDateChange('beginDate')}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              minDate={new Date()}
              minTime={
                new Date(
                  selectedBeginDateTemp && isFutureDate(selectedBeginDateTemp)
                    ? new Date().setHours(0, 0)
                    : new Date()
                )
              }
              maxTime={new Date(new Date().setHours(23, 59))}
              dateFormat="MM/dd/yyyy HH:mm"
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer>
            <span>Event End :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <InputText
              data-testid="end-date"
              id="endDate"
              value={
                selectedEndDate
                  ? selectedEndDate
                      .toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                      .replace(',', '')
                  : ''
              }
              onClick={() => handleDateTextBoxClick(endDatePickerRef)}
            />
            <EStyles.StyledDatePicker
              ref={endDatePickerRef}
              selected={selectedEndDateTemp}
              onChange={(date: Date) => {
                setSelectedEndDateTemp(date);
              }}
              onCalendarClose={() => handleDateChange('endDate')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              minDate={new Date()}
              minTime={
                new Date(
                  selectedEndDateTemp && isFutureDate(selectedEndDateTemp)
                    ? new Date().setHours(0, 0)
                    : new Date()
                )
              }
              maxTime={new Date(new Date().setHours(23, 59))}
              dateFormat="MM/dd/yyyy HH:mm"
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer>
            <span>Area :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <InputText
              data-testid="area"
              id="area"
              value={formvalue['area']}
              disabled={true}
              onChange={(e) => handleFormChange(e)}
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer>
            <span>Signal Name :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <Dropdown
              data-testid="signal-name"
              id="signalName"
              value={formvalue['signalName']}
              onChange={(e:any) => handleFormChange(e)}
              options={signalNames}
              optionLabel="name"
              optionValue="value"
              placeholder="Select a signal name"
              className="w-full"
            />
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.LeftColumnContainer className="mb-6">
            <span>Signal Payload Value :</span>
          </EStyles.LeftColumnContainer>
          <EStyles.RightColumnContainer>
            <EStyles.SignalPayloadContainer>
              {signalPayloadValues.map((payloadValues) => {
                return (
                  <div
                    key={payloadValues.key}
                    className="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3"
                  >
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId={payloadValues.name}
                        name="category"
                        value={payloadValues}
                        onChange={(e) => handleRadioBoxChange(e.value)}
                        checked={
                          selectedPayload
                            ? selectedPayload.key === payloadValues.key
                            : undefined
                        }
                        data-testid={`signal-payload-radio-${payloadValues.name}`}
                      />
                      <label htmlFor={payloadValues.name} className="ml-2" data-testid="signal-payload-label">
                        {payloadValues.name}
                      </label>
                    </div>
                  </div>
                );
              })}
            </EStyles.SignalPayloadContainer>
            <EStyles.CheckboxContainer>
              <Checkbox
                data-testid="test-event"
                inputId="testEvent"
                name="testEvent"
                className={'testEvent'}
                onChange={(e) => handleCheckBoxChange('testEvent', e.checked!)}
                checked={formvalue['testEvent'] === true}
              />
              <label htmlFor="testEvent">Is a Test Event?</label>
            </EStyles.CheckboxContainer>
          </EStyles.RightColumnContainer>
        </EStyles.RowContainer>
        <EStyles.RowContainer>
          <EStyles.EventsContainerButton onClick={clearForm}>Clear</EStyles.EventsContainerButton>
          <EStyles.EventsContainerButton id="publish" onClick={handleSubmit}>
            {mode === 'create' ? 'Schedule' : 'Update Schedule'}
          </EStyles.EventsContainerButton>
        </EStyles.RowContainer>
      </EStyles.EventsInputContainer>
    </EStyles.EventsFormContainer>
  );
};
