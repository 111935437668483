import moment from "moment";
import { getSignalNameByKey } from "../types/signalValues";
import { ScheduleLogDto } from "../types/schedule";
import { EventDto } from "../types/event";
import { formatDateMoment } from "./Utility";
import { EventDeliveryDto } from "../types/event.delivery";
type LogDto = ScheduleLogDto | EventDto;

export const responseAcknowledgedTemplateForEvent = (rowData: EventDeliveryDto) => {
    return rowData?.response_acknowledged ? 'Success' : 'Failed';
};

export const responseCodeTemplate = (rowData: any) => {
    return rowData?.payload.response_code ? rowData?.payload.response_code : 'NULL';
};

export const responseCodeTemplateForEvent = (rowData: EventDto) => {
    return rowData?.response_code ? rowData?.response_code : 'NULL';
};
export const responseCreatedDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.created_at);
};

export const responseScheduleDateTemplate = (rowData: ScheduleLogDto) => {
    return rowData?.schedule ? moment(rowData?.schedule).format('MM/DD/YYYY, HH:mm') : 'NULL';
};

export const responseStartDateTemplate = (rowData: ScheduleLogDto) => {
    return formatDateMoment(rowData?.payload.start_time);
};

export const responseStartDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.start_time);
};

export const responseEndDateTemplate = (rowData: ScheduleLogDto) => {
    return formatDateMoment(rowData?.payload.end_time);
};

export const responseEndDateTemplateForEvent = (rowData: EventDto) => {
    return formatDateMoment(rowData?.end_time);
};

export const responsePayloadTemplate = (rowData: ScheduleLogDto) => {
    return getSignalNameByKey(rowData.payload.signal_payload);
};

export const responsePayloadTemplateForEvent = (rowData: EventDto) => {
    return getSignalNameByKey(rowData.signal_payload);
};