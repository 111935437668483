import { useState, useEffect, useCallback, useRef } from "react";
import { HttpStatusCode } from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { showToast } from "./Utility";
import { UploadLogService } from "../service/uploadService";

export const useFetchTariffs = () => {
    const navigate = useNavigate();
    const [tariffLogs, setTariffLogs] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [publishedEventId, setPublishedEventId] = useState<string>();
    const [loadTariffs, setLoadTariffs] = useState(true);
    const toast = useRef<Toast>(null);

    const fetchTariffs = useCallback(async () => {
        setIsLoading(true);
        let response = await UploadLogService();
        try {
            if (response?.status === 200) {
                setIsLoading(false);
                setTariffLogs(response?.data?.result.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
            } else if (response.status === HttpStatusCode.Forbidden) {
                navigate("/login");
                setIsLoading(false);
                console.log("Error fetching events " + response.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            showToast(toast, "error", "Oops!", "Error Fetching Events", false);
        }
    }, [navigate]);

    useEffect(() => {
        if (loadTariffs) {
            fetchTariffs();
            setLoadTariffs(false);
        }
        if (publishedEventId?.includes('uploaded')) {
            showToast(toast, "success", "Complete!", `${publishedEventId.split('uploaded')[1]} has been uploaded successfully!`, false);
        } else if (publishedEventId?.includes('failed')) {
            showToast(toast, "error", `Failed to Upload: ${publishedEventId.split('failed')[1]}`, `File has been already exists , Please change the file name`, false);
        } else if (publishedEventId?.includes('Cancelled')) {
            showToast(toast, "warn", "Complete!", `${publishedEventId.split('Cancelled')[1]} has been deleted successfully!`, false);
        }
    }, [loadTariffs, fetchTariffs, isLoading, publishedEventId]);

    return { tariffLogs, isLoading,setIsLoading, setLoadTariffs, setPublishedEventId ,publishedEventId};
};