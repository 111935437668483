import { useState, useEffect } from 'react';

export const useResponsiveTable = () => {
  const [scrollHeight, setScrollHeight] = useState(window.innerHeight + 'px');
  const [scrollWidth, setScrollWidth] = useState(window.innerWidth + 'px');
  const [tableSize, setTableSize] = useState<any>('normal');
  const [paginatorTemplate, setPaginatorTemplate] = useState(
    'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
  );
  const [dataTableClass, setDataTableClass] = useState('');

  const updateWidth = () => {
    if (window.innerWidth < 769) {
      setScrollWidth(window.innerWidth - 70 + 'px');
    } else {
      setScrollWidth(window.innerWidth - 160 + 'px');
    }
  };

  const updateHeight = () => {
    setScrollHeight(
      window.innerHeight < 600
        ? window.innerHeight - 150 + 'px'
        : window.innerHeight - 210 + 'px'
    );
  };

  useEffect(() => {
    updateWidth();
    updateHeight();

    const handleResize = () => {
      updateWidth();
      updateHeight();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 1599) {
        setTableSize('small');
      } else {
        setTableSize('normal');
      }
    };

    const updateHeightTable = () => {
      if (window.outerHeight < 600) {
        setDataTableClass('datatable-small');
        setTableSize('small');
      }
    };

    updateSize();
    updateHeightTable();

    window.addEventListener('resize', updateSize);
    window.addEventListener('resize', updateHeightTable);
    return () => {
      window.removeEventListener('resize', updateSize);
      window.removeEventListener('resize', updateHeightTable);
    };
  }, []);

  useEffect(() => {
    const updatePaginatorTemplate = () => {
      if (window.innerWidth >= 1024) {
        setPaginatorTemplate(
          'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
        );
        setDataTableClass('');
      } else {
        setPaginatorTemplate(
          'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
        );
        setDataTableClass('datatable-small');
      }
    };

    updatePaginatorTemplate();

    window.addEventListener('resize', updatePaginatorTemplate);
    return () => window.removeEventListener('resize', updatePaginatorTemplate);
  }, []);


  useEffect(() => {
    updateWidth();
    updateHeight();
  }, [updateWidth, updateHeight]);

  return {
    scrollHeight,
    scrollWidth,
    tableSize,
    paginatorTemplate,
    dataTableClass,
  };
};
