import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconContext } from 'react-icons';
import logo from './../../assets/images/logoimage.svg';
import { NStyles } from './navbarStyles';
import { CgProfile } from 'react-icons/cg';
import { showToast } from '../../Utils/Utility';
import { Toast } from 'primereact/toast';
import { validateService } from '../../service/authService';
import { Dialog } from 'primereact/dialog';

export const Navbar: React.FC<{}> = () => {
    const iconContextValue = useMemo(() => ({ size: '2.5rem', color: "var(--primary-ui)" }), []);
    const [profileData, setProfileData] = useState<any>({});
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const toast = useRef<Toast>(null);

    const validateSession = async () => {
        try {
            let response: any = await validateService();
            if (response?.status === 200) {
                setProfileData(response?.data);
            }
        } catch (error) {
            showToast(toast, "error", "Oops!", "Error", false);
        }
    };
    useEffect(() => {
        validateSession();
    }, []);

    const handleProfileIconClick = () => {
        setIsDialogVisible(true);
    };

    const hideDialog = () => {
        setIsDialogVisible(false);
    };
    return (
        <>
               
            <IconContext.Provider value={iconContextValue}>
                <NStyles.NavbarContainer>
                    <NStyles.LogoContainer>
                        <NStyles.Logo src={logo} alt='logoimage.png'></NStyles.Logo>
                        <NStyles.HeaderText>Southern Company</NStyles.HeaderText>
                    </NStyles.LogoContainer>
                        {
                        profileData?.username ?
                        <>
                    <NStyles.ProfileIcon onClick={handleProfileIconClick}>
                    <NStyles.ProfileText>Hello {profileData?.username}! &nbsp;</NStyles.ProfileText>
                           
                            <CgProfile />
                    </NStyles.ProfileIcon>
                            </> 
                        :
                        ''}
                </NStyles.NavbarContainer>
                <Dialog header="Profile Details" visible={isDialogVisible} style={{ width: '50vw' }} onHide={hideDialog} pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
                    <div>
                        <p><strong>Name:</strong> {profileData?.username}</p>
                        <p><strong>Email:</strong> {profileData?.email}</p>
                    </div>
                </Dialog>
            </IconContext.Provider>

        </>
    )
};