import axios from "axios";

export const UploadFileService = (file: File,fileExtension:string,  filename: string, remarks: string): Promise<any> => {
    const data = new FormData();
    data.append('file', file);
    data.append('remarks', remarks);
    data.append('filename',`${filename}.${fileExtension}`)
    return axios.post('https://tariff-service-345158162856.us-central1.run.app/v1/tariffs', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxBodyLength: Infinity,
    });
  };

export const UploadLogService = () => {
  return axios.get('https://tariff-service-345158162856.us-central1.run.app/v1/tariffs')
}
export const UploadDeleteService = (folder: string, filename: string, version: string): Promise<any> => {
  return axios.delete(`https://tariff-service-345158162856.us-central1.run.app/v1/tariffs?folder=${folder}&filename=${filename}&version=${version}`);
};