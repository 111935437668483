export const signalPayloadValues = [
    { name: 'V1G', key: 1 },
    { name: 'V2H', key: 2 },
    { name: 'V2G', key: 3 },
];

export interface Payload {
    name: string;
    key: number;
}

export const getSignalNameByKey = (key: number) => {
    const signal = signalPayloadValues.find(signal => signal.key === key);
    return signal ? signal.name : 'NULL';
}