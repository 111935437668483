const checkCsvFormat = (csvData: string): boolean => {
    const lines = csvData.split('\n').filter(line => line.trim() !== '');
    if (lines.length <= 2) {
      return false;
    }
    lines.shift();
  
    const headerLine: any = lines.shift();
    const headers = headerLine.split(',').map((header: any) => header.trim());
  
    const expectedHeaders = ['Date(MM/DD/YYYY)', 'Start Hour(0-23)', 'Start Min(0-59)', 'Duration(Min)', 'Value'];
    if (!headersMatch(headers, expectedHeaders)) {
      return false;
    }
  
    return lines.every(line => validateLine(line, expectedHeaders.length));
  };

const headersMatch = (headers: string[], expectedHeaders: string[]): boolean => {
    return expectedHeaders.every((header, index) => headers[index] === header);
};

const validateLine = (line: string, expectedLength: number): boolean => {
    const fields = line.split(',').map(field => field.trim());
    if (fields.length !== expectedLength) {
        return false;
    }

    return (
        validateDate(fields[0]) &&
        validateHour(fields[1]) &&
        validateMinute(fields[2]) &&
        validateDuration(fields[3]) &&
        validateValue(fields[4])
    );
};

const validateDate = (dateStr: string): boolean => {
    const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/\d{4}$/;
    return dateRegex.test(dateStr);
};

const validateHour = (hourStr: string): boolean => {
    const hour = parseInt(hourStr, 10);
    return !isNaN(hour) && hour >= 0 && hour <= 24;
};

const validateMinute = (minuteStr: string): boolean => {
    const minute = parseInt(minuteStr, 10);
    return !isNaN(minute) && minute >= 0 && minute <= 59;
};

const validateDuration = (durationStr: string): boolean => {
    const duration = parseInt(durationStr, 10);
    return !isNaN(duration) && duration > 0;
};

const validateValue = (valueStr: string): boolean => {
    const value = parseFloat(valueStr);
    return !isNaN(value) && value >= 0;
};

export { checkCsvFormat, headersMatch, validateLine, validateDate, validateHour, validateMinute, validateDuration, validateValue };