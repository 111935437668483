import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LStyles } from '../events/EventsLog/eventlogsStyles';
import { Dialog } from 'primereact/dialog';
import {CSVReader} from '../../Utils/fileUpload';
import { ErrorStyles } from '../error/errorStyles';
import { validateService } from '../../service/authService';
import { ClipLoader } from 'react-spinners';
import { StartStyles } from './startpageStyles';

const StartPage = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState<string>()
  const [profileData, setProfileData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  const hideDialog = () => {
    setVisible(false);
  }

  const handleDialog = (title: string) => {
    setVisible(true)
    setDialogTitle(title)
  }

  const validateSession = async () => {
    setIsLoading(true)
    try {
      let response: any = await validateService();
      if (response?.status === 200) {
        setProfileData(response);
        setIsLoading(false)
      }
    } catch (error: any) {
      console.log(error?.response?.data?.message);
      
      setProfileData(error?.response?.data?.message);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    validateSession();
  }, []);

  return (
    <LStyles.EventsLogContainer>
    <ErrorStyles.Container>
      {isLoading ?
        <div className="loader">
          <ClipLoader color="var(--primary-ui)" loading={isLoading} size={100} aria-label="Publishing" data-testid="loader" className="loader-img" />
        </div>
        : ''}
      <StartStyles.HeaderText>Grid Connect Dashboard</StartStyles.HeaderText>
    {!isLoading ? <>
      {profileData?.data?.username ?
        <>
          <LStyles.CustomButton onClick={() => handleButtonClick('/events')} className="m-2 w-13rem">Demand Response</LStyles.CustomButton>
          <LStyles.CustomButton onClick={() => handleDialog("Upload Variable Tariff Rate File")} className="m-2 w-13rem" >Upload Variable Tariff</LStyles.CustomButton>
          <LStyles.CustomButton className="m-2 w-13rem" >View Customer Data</LStyles.CustomButton>
          <Dialog
            visible={visible}
            style={{ width: '60vw' }}
            className={"p-fluid"}
            header={dialogTitle}
            modal
            onHide={hideDialog}
            maximizable={window.innerWidth < 768 ? false : true}
            maximized={window.innerWidth < 768 ? true : false}
            dismissableMask
            pt={{ header: { style: { padding: "10px 25px 10px 25px" } }, headerTitle: { style: { fontWeight: 'bolder' } } }}>
            <CSVReader
            />
          </Dialog>
        </>
        :
        <>
          { !profileData?.data?.username ?
            <LStyles.CustomButton className="m-3 w-10rem" onClick={() => navigate('/login')}>Login</LStyles.CustomButton>
            : null}
        </>
      }
      </>
      :''}
    </ErrorStyles.Container>
    </LStyles.EventsLogContainer>
  );
};

export default StartPage;