import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { TbInfoCircle, TbCopy, TbTrash } from 'react-icons/tb';
import { EOverlayStyles } from '../EventsOverlay/EventsOverlayStyles';
import { formatDateMoment } from '../../../Utils/Utility';

interface RequestIdTemplateProps {
  rowData: any;
  onConfirm?: (rowData: any) => void;
  type?: string;
  toastRef?: React.RefObject<any>;
}

export const RequestIdTemplate: React.FC<RequestIdTemplateProps> = ({ rowData, onConfirm, type, toastRef }) => {
  const toast = useRef<Toast>(null);
  const op = useRef<any>(null);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(rowData.request_id)
      .then(() => {
        toast.current?.show({ severity: 'success', detail: 'Id copied to clipboard' });
      })
      .catch((err) => {
        toast.current?.show({
          severity: 'info',
          summary: 'Error',
          detail: `Failed to copy tooltip content : ${err}`,
        });
      });
  };

  const isDeleteDisabled =
    rowData.status === 'PUBLISHED' || rowData.status === 'CANCELLED' || rowData.status === 'FAILED';

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
      <Button
        rounded
        text
        data-testid="info-button"
        icon={<TbInfoCircle />}
        style={{ height: '0.25rem' }}
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel
        ref={op}
        className="text-sm fadein animation-duration-100"
        closeOnEscape
        dismissable
        pt={{ content: { className: 'p-2' } }}
      >
        <table style={{ borderCollapse: 'collapse' }}>
          <tbody>
            {/* Common fields */}
            <tr>
              <EOverlayStyles.KeyCellStyle>Request ID</EOverlayStyles.KeyCellStyle>
              <EOverlayStyles.CellStyle>
                <span>{rowData?.request_id}</span>
                <button
                  className="ml-2"
                  onClick={handleCopy}
                  data-testid="copy-button"
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                >
                  <TbCopy />
                </button>
              </EOverlayStyles.CellStyle>
            </tr>
            <tr>
              <EOverlayStyles.KeyCellStyle>Test Event</EOverlayStyles.KeyCellStyle>
              <EOverlayStyles.CellStyle>
                <span>{rowData?.payload?.test_event ? 'Yes' : 'No'}</span>
              </EOverlayStyles.CellStyle>
            </tr>
            <tr>
              <EOverlayStyles.KeyCellStyle>Response Required</EOverlayStyles.KeyCellStyle>
              <EOverlayStyles.CellStyle>
                <span>{rowData?.payload?.response_required ? 'Yes' : 'No'}</span>
              </EOverlayStyles.CellStyle>
            </tr>
             {type === 'event' ? (
              <>
                <tr>
                  <EOverlayStyles.KeyCellStyle>VTN Name</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.vtn_name}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Duration</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.duration}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Time Zone</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.time_zone}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Created By</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.created_by}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Created At</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{formatDateMoment(rowData?.created_at)}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Target</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.payload?.ven_id}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Time Zone</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.payload?.time_zone}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
                <tr>
                  <EOverlayStyles.KeyCellStyle>Created By</EOverlayStyles.KeyCellStyle>
                  <EOverlayStyles.CellStyle>
                    <span>{rowData?.payload?.created_by}</span>
                  </EOverlayStyles.CellStyle>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </OverlayPanel>
      {onConfirm && (
        <Button
          disabled={isDeleteDisabled}
          text
          icon={<TbTrash />}
          style={{ height: '0.25rem' }}
          onClick={() => onConfirm(rowData)}
          className="p-button-danger"
          id={`delete-${rowData.id}`}
        />
      )}
    </div>
  );
};
