import styled from "styled-components";

const NavbarContainer = styled.div`
    width           : 100%;
    flex-grow       : 0;
    display         : flex;
    justify-content : space-between;
    background      : #ffffff;
`;

const Logo = styled.img.attrs({className:"scalein animation-duration-200"})`
    width: 3em;
    height: 3em;
    align-items: center;
    margin : 0px 0px 0px 10px; 
    display :  flex;

    @media (max-width: 768px) {
        margin-left : 5px; 
    }
`;

const LogoContainer = styled.div`
    align-items: center;
    padding : 10px 5px;
    display :   flex;

    @media (max-width: 768px) {
        padding : 5px;
    }
`;

const Logout = styled.div`
    display: flex;
    align-items: center;
    padding :   30px;
`; 

const HeaderText = styled.h1.attrs({className:"scalein animation-duration-200"})`
    margin: 5px;
    font-size: 2em;
    font-weight: bold;
    background: var(--primary-ui);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 5px;
    }

    @media (max-width: 382px) {
        font-size: 0.95rem;
        padding: 5px;
    }
`;

const ProfileText = styled.div.attrs({className:"scalein animation-duration-200"})`
    font-size: 1em;
    font-weight: bold;
    background: var(--primary-ui);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
    @media (max-width: 768px) {
        font-size: 0.75rem;
        padding: 5px;
    }
`;

const ProfileIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right:10px;

    @media (max-width: 768px) {
        font-size: 1.25rem;
        padding: 0px;
        margin-right:5px;
    }
`;

export const NStyles = {
  NavbarContainer,
  Logo,
  LogoContainer,
  Logout,
  HeaderText,
  ProfileIcon,
  ProfileText
};